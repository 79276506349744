import styled from "styled-components";
import {getCssSizeValue, getPropSafely} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {mockData} from "@/components/NamesAndPaymentsCarousel/mockupData";
import {ICONS, LOCALES, PAGES} from "@/constants";
import Icon from "@/components/common/Icon/Icon";
import useGlobals from "@/hooks/useGlobals";
import useSWR from "swr";
import Marquee from "react-fast-marquee";
import {useRef} from "react";
import {useInView} from "framer-motion";
import {useRouter} from "next/router";
import {useIsMobile} from "@/hooks/useDevice";
import PreserveSearchParamsLink from "@/components/common/Links/PreserveSearchParamsLink";

const Wrapper = styled(PreserveSearchParamsLink)`
	background: ${({theme}) => theme.colors.richBlue};
	display: flex;
	align-items: flex-end;
	overflow: hidden;
	height: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    padding-bottom: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};;
	
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding-bottom: ${getCssSizeValue(70, BREAKPOINTS.TABLET)};;
        height: ${getCssSizeValue(240, BREAKPOINTS.TABLET)};
	}

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        height: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
		padding-bottom: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};;
	}
`

const StyledFirstItem = styled.div`
	color: ${({theme}) => theme.colors.platinum};
	padding-left: var(--paddingX);
    div {
        height: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
        max-width: unset;
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        div {
            height: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-right: ${getCssSizeValue(8, BREAKPOINTS.DESKTOP)};
        padding-left: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
        div {
            height: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
        }
    }
	
	.icon {
		svg {
			rect {
                fill: ${props => props.theme.colors.antiflashWhite};
			}
			path {
				fill: ${props => props.theme.colors.antiflashWhite};
			}
			polygon {
                fill: ${props => props.theme.colors.chilliRed};
				
				&.white {
                    fill: ${props => props.theme.colors.antiflashWhite} !important;
				}
			}
		}
	}
`

const StyledMarquee = styled(Marquee)`
	.rfm-initial-child-container {
		align-items: flex-end !important;
	}
`

const StyledSinglePayment = styled.div`
    position: relative;
    color: ${({theme}) => theme.colors.platinum};
    display: flex;
	background: ${({theme}) => theme.colors.richBlue};
	translate: ${getCssSizeValue(-90)};
	align-items: flex-end;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        translate: unset;
	}
	
    .name-wrapper {
		translate: 0 ${getCssSizeValue(2, BREAKPOINTS.MOBILE)};
		padding: 0 ${getCssSizeValue(8)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			padding: unset;
		}
	}
	
	div {
		.payment-info {
			font-weight: ${({theme}) => theme.fontWeight.bold};
		}
		
		&.divider {
			height: 1px;
			width: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
			background: ${({theme}) => theme.colors.chilliRed};

            @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
                margin: 0 ${getCssSizeValue(12, BREAKPOINTS.TABLET)};
                width: ${getCssSizeValue(120, BREAKPOINTS.TABLET)};
            }

            @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
                margin: 0 ${getCssSizeValue(8, BREAKPOINTS.DESKTOP)};
                width: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
            }
		}
	}
`

const fetcher = url => fetch(url, {
	headers: {
		'X-Organization': process.env.X_ORGANIZATION_HEADER,
	}
}).then(r => r.json())

function useNamesAndPaymentsCarouselData() {
	const shouldDonorsCarouselUseMockData = useGlobals(state => state.shouldDonorsCarouselUseMockData)
	const url = `${process.env.NEXT_PAYMENT_URL}statistics/last-payments`

	const { data, error, isLoading } = useSWR(url, fetcher);

	const useMockData = shouldDonorsCarouselUseMockData || error

	return {
		shouldDonorsCarouselUseMockData: useMockData,
		data,
		error,
		isLoading
	}
}

function getIconNameBasedOnLocale(locale) {
	switch (locale) {
		case LOCALES.PL:
			return ICONS.PAYMENTS_SLOGAN_PL
		case LOCALES.EN:
			return ICONS.PAYMENTS_SLOGAN_EN
		default:
			return ICONS.PAYMENTS_SLOGAN_EN
	}
}

export default function NamesAndPaymentsCarousel() {
	const isMobile = useIsMobile()
	const { locale } = useRouter()
	const { shouldDonorsCarouselUseMockData, data, error, isLoading } = useNamesAndPaymentsCarouselData()

	const namesAndPaymentsCarouselText = useGlobals(state => getPropSafely(state, 'namesAndPaymentsCarouselText'))
	const source = shouldDonorsCarouselUseMockData ? mockData : data

	const wrapperRef = useRef(null);
	const inView = useInView(wrapperRef, { once: true });

	const iconName = getIconNameBasedOnLocale(locale)
	const SPEED_PER_ITEM = isMobile ? 1 : 1

	return (
		<Wrapper href={`/${PAGES.I_PAY}`}>
			<div ref={wrapperRef}>
				{source && (
					<StyledMarquee
						speed={inView ? ((source?.length * SPEED_PER_ITEM) || 0) : 0}
					>
						<StyledFirstItem
						>
							<Icon name={iconName} className='no-color' />
						</StyledFirstItem>
						{source.map((item, index) => {
							const name = item?.firstName || ''
							const amount = item?.amount || ''
							return (
								<StyledSinglePayment key={index}>
									<div className='typo-strawford typo-strawford-31 name-wrapper'>
										<span>{name}</span>
										<span className='payment-info'>
											{`, ${namesAndPaymentsCarouselText} ${amount} zł`}
										</span>
									</div>
									{index !== source.length - 1 && (
										<div className='divider' />
									)}
								</StyledSinglePayment>
							)
						})}
					</StyledMarquee>
				)}
			</div>
		</Wrapper>
	)
}
