export const mockData = [
	{"id": 1, "firstName": "Anna", "amount": 65},
	{"id": 2, "firstName": "Piotr", "amount": 300},
	{"id": 3, "firstName": "Katarzyna", "amount": 50},
	{"id": 4, "firstName": "Michał", "amount": 160},
	{"id": 5, "firstName": "Magdalena", "amount": 10},
	{"id": 6, "firstName": "Tomasz", "amount": 250},
	{"id": 7, "firstName": "Agnieszka", "amount": 100},
	{"id": 8, "firstName": "Marcin", "amount": 200},
	{"id": 9, "firstName": "Ewa", "amount": 30},
	{"id": 10, "firstName": "Jakub", "amount": 350},
	{"id": 11, "firstName": "Joanna", "amount": 65},
	{"id": 12, "firstName": "Mateusz", "amount": 160},
	{"id": 13, "firstName": "Zofia", "amount": 50},
	{"id": 14, "firstName": "Paweł", "amount": 100},
	{"id": 15, "firstName": "Monika", "amount": 300},
	{"id": 16, "firstName": "Krzysztof", "amount": 10},
	{"id": 17, "firstName": "Alicja", "amount": 200},
	{"id": 18, "firstName": "Andrzej", "amount": 250},
	{"id": 19, "firstName": "Barbara", "amount": 30},
	{"id": 20, "firstName": "Łukasz", "amount": 350},
	{"id": 21, "firstName": "Natalia", "amount": 100},
	{"id": 22, "firstName": "Adam", "amount": 10},
	{"id": 23, "firstName": "Karolina", "amount": 300},
	{"id": 24, "firstName": "Grzegorz", "amount": 50},
	{"id": 25, "firstName": "Paulina", "amount": 160},
	{"id": 26, "firstName": "Rafał", "amount": 250},
	{"id": 27, "firstName": "Małgorzata", "amount": 200},
	{"id": 28, "firstName": "Dariusz", "amount": 30},
	{"id": 29, "firstName": "Weronika", "amount": 65},
	{"id": 30, "firstName": "Sebastian", "amount": 350},
	{"id": 31, "firstName": "Dorota", "amount": 100},
	{"id": 32, "firstName": "Dominik", "amount": 10},
	{"id": 33, "firstName": "Emilia", "amount": 200},
	{"id": 34, "firstName": "Patryk", "amount": 160},
	{"id": 35, "firstName": "Justyna", "amount": 50},
	{"id": 36, "firstName": "Daniel", "amount": 300},
	{"id": 37, "firstName": "Iwona", "amount": 250},
	{"id": 38, "firstName": "Maciej", "amount": 30},
	{"id": 39, "firstName": "Patrycja", "amount": 65},
	{"id": 40, "firstName": "Szymon", "amount": 350},
	{"id": 41, "firstName": "Beata", "amount": 100},
	{"id": 42, "firstName": "Damian", "amount": 10},
	{"id": 43, "firstName": "Ewelina", "amount": 200},
	{"id": 44, "firstName": "Artur", "amount": 160},
	{"id": 45, "firstName": "Kamila", "amount": 50},
	{"id": 46, "firstName": "Wojciech", "amount": 300},
	{"id": 47, "firstName": "Izabela", "amount": 250},
	{"id": 48, "firstName": "Bartłomiej", "amount": 30},
	{"id": 49, "firstName": "Marianna", "amount": 65},
	{"id": 50, "firstName": "Kamil", "amount": 350},
	{"id": 51, "firstName": "Anna", "amount": 100},
	{"id": 52, "firstName": "Piotr", "amount": 10},
	{"id": 53, "firstName": "Katarzyna", "amount": 200},
	{"id": 54, "firstName": "Michał", "amount": 160},
	{"id": 55, "firstName": "Magdalena", "amount": 50},
	{"id": 56, "firstName": "Tomasz", "amount": 300},
	{"id": 57, "firstName": "Agnieszka", "amount": 250},
	{"id": 58, "firstName": "Marcin", "amount": 30},
	{"id": 59, "firstName": "Ewa", "amount": 65},
	{"id": 60, "firstName": "Jakub", "amount": 350},
	{"id": 61, "firstName": "Joanna", "amount": 100},
	{"id": 62, "firstName": "Mateusz", "amount": 10},
	{"id": 63, "firstName": "Zofia", "amount": 200},
	{"id": 64, "firstName": "Paweł", "amount": 160},
	{"id": 65, "firstName": "Monika", "amount": 50},
	{"id": 66, "firstName": "Krzysztof", "amount": 300},
	{"id": 67, "firstName": "Alicja", "amount": 250},
	{"id": 68, "firstName": "Andrzej", "amount": 30},
	{"id": 69, "firstName": "Barbara", "amount": 65},
	{"id": 70, "firstName": "Łukasz", "amount": 350},
	{"id": 71, "firstName": "Natalia", "amount": 100},
	{"id": 72, "firstName": "Adam", "amount": 10},
	{"id": 73, "firstName": "Karolina", "amount": 200},
	{"id": 74, "firstName": "Grzegorz", "amount": 160},
	{"id": 75, "firstName": "Paulina", "amount": 50},
	{"id": 76, "firstName": "Rafał", "amount": 300},
	{"id": 77, "firstName": "Małgorzata", "amount": 250},
	{"id": 78, "firstName": "Dariusz", "amount": 30},
	{"id": 79, "firstName": "Weronika", "amount": 65},
	{"id": 80, "firstName": "Sebastian", "amount": 350},
	{"id": 81, "firstName": "Dorota", "amount": 100},
	{"id": 82, "firstName": "Dominik", "amount": 10},
	{"id": 83, "firstName": "Emilia", "amount": 200},
	{"id": 84, "firstName": "Patryk", "amount": 160},
	{"id": 85, "firstName": "Justyna", "amount": 50}
]